import * as React from "react";
import { FunctionComponent, useState } from "react";
import { Button, Heading, Input, Link, Page } from "../components";
import { useStaticQuery, graphql } from "gatsby";

import "./ChangeAddress.css";

const Recaptcha = require("react-recaptcha");

const ChangeAddressForm: FunctionComponent = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      allContentfulGeneralEditableField {
        edges {
          node {
            title
            pageSection
            childContentfulGeneralEditableFieldContentRichTextNode {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
    }
  `);

  const [
    isCorrespondenceSameAsBilling,
    setCorrespondenceSameAsBilling
  ] = useState(false);

  const toggleCorrespondenceSameAsBilling = (): void => {
    setCorrespondenceSameAsBilling(!isCorrespondenceSameAsBilling);
  };

  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const paymentEmailArray = data.allContentfulGeneralEditableField.edges.filter(
    field => field.node.pageSection === "paymentEmail"
  );
  const paymentEmail = paymentEmailArray.length
    ? paymentEmailArray[0].node.childContentfulGeneralEditableFieldContentRichTextNode.childContentfulRichText.html.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      )
    : null;

  const paymentPhoneNumberArray = data.allContentfulGeneralEditableField.edges.filter(
    field => field.node.pageSection === "paymentPhoneNumber"
  );
  const paymentPhoneNumber = paymentPhoneNumberArray.length
    ? paymentPhoneNumberArray[0].node.childContentfulGeneralEditableFieldContentRichTextNode.childContentfulRichText.html.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      )
    : null;

  const recaptchaVerifyCallback = response => {
    setCaptchaVerified(true);
    setCaptchaToken(response);
  };

  const recaptchaExpiredCallback = _response => {
    setCaptchaVerified(false);
    setCaptchaToken("");
  };

  return (
    <Page layout="default" title="Change of Address">
      <form
        id="change-address-form"
        encType="multipart/form-data"
        method="POST"
        action={`${
          process.env.GATSBY_HMCMGT_API_GATEWAY_URL
        }/change-of-address`}
      >
        <Heading style={{ textAlign: "center" }} level={1}>
          Change of Address
        </Heading>
        <p style={{ textAlign: "center" }}>
          {`Please enter the information below to change the billing address or correspondence address for your account.`}
        </p>

        <p style={{ textAlign: "center" }}>
          {`Please note - this is only used to change the address. To change or
        update the spelling of a name please contact an Accounting Specialist 
        at ${paymentPhoneNumber} or via email at`}{" "}
          <Link href={`${paymentEmail}`}>{paymentEmail}</Link>
        </p>
        <hr />
        <fieldset>
          <legend>Owner Information</legend>

          <div>
            <div className="input--split">
              <label htmlFor="first_name">First Name</label>
              <Input id="first_name" name="first_name" required />
            </div>
            <div className="input--split">
              <label htmlFor="last_name">Last Name</label>
              <Input id="last_name" name="last_name" required />
            </div>
          </div>

          <div>
            <label htmlFor="property_name">Property Name</label>
            <Input id="property_name" name="property_name" required />
          </div>

          <div>
            <label htmlFor="unit_number">Unit Number</label>
            <Input id="unit_number" name="unit_number" required />
          </div>

          <div>
            <label htmlFor="email_address">Email Address</label>
            <Input id="email_address" name="email_address" required />
          </div>
        </fieldset>

        <fieldset>
          <legend>Billing Address</legend>

          <div>
            <label htmlFor="address_line1">Address Line 1</label>
            <Input id="address_line1" name="billing_address_street" required />
          </div>

          <div>
            <label htmlFor="address_line2">Address Line 2</label>
            <Input id="address_line2" name="billing_address_secondary" />
          </div>

          <div>
            <label htmlFor="city">City</label>
            <Input id="city" name="billing_address_city" required />
          </div>

          <div>
            <div className="input--split">
              <label htmlFor="state">State</label>
              <Input id="state" name="billing_address_state" required />
            </div>
            <div className="input--split">
              <label htmlFor="zipcode">ZIP Code</label>
              <Input id="zipcode" name="billing_address_zipcode" required />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Correspondence Address</legend>
          <Input
            type="checkbox"
            id="correspondence_same_as_billing"
            name="is_correspondence_address_same_as_billing_address"
            checked={isCorrespondenceSameAsBilling}
            onChange={toggleCorrespondenceSameAsBilling}
          />
          <label htmlFor="correspondence_same_as_billing">
            Same as billing address
          </label>

          <div id="correspondence_address_fields">
            <fieldset disabled={isCorrespondenceSameAsBilling}>
              <div>
                <label htmlFor="address_line1">Address Line 1</label>
                <Input
                  id="address_line1"
                  name="correspondence_address_street"
                  required
                />
              </div>
              <div>
                <label htmlFor="address_line2">Address Line 2</label>
                <Input
                  id="address_line2"
                  name="correspondence_address_secondary"
                  required
                />
              </div>
              <div>
                <label htmlFor="city">City</label>
                <Input id="city" name="correspondence_address_city" required />
              </div>
              <div>
                <div className="input--split">
                  <label htmlFor="state">State</label>
                  <Input
                    id="state"
                    name="correspondence_address_state"
                    required
                  />
                </div>
                <div className="input--split">
                  <label htmlFor="zipcode">ZIP Code</label>
                  <Input
                    id="zipcode"
                    name="correspondence_address_zipcode"
                    required
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </fieldset>
        <hr />
        <div>
          <p>
            {`To change or update the spelling of a name, one of the following documents must be submitted: 
          1) birth certificate; 2) marriage certificate or 3) deed. Please note that a name change refers 
          to a spelling correction or update only, and not to a change of ownership.`}
          </p>
          <label htmlFor="attach_document">Attach Document</label>
          <br />
          <Input type="file" id="attach_document" name="document_file" />
        </div>
        <hr />
        <p>
          <Button
            style={{ fontWeight: "normal" }}
            href="/electronic-communications-notice"
            target="_blank"
            rel="noreferrer"
          >
            View the Consent and Notice Regarding Electronic Communication{" "}
            <span aria-hidden="true">&rarr;</span>
          </Button>
        </p>
        <Input
          type="checkbox"
          id="agrees_to_electronic_communication"
          name="agrees_to_electronic_communication"
          required
        />
        <label htmlFor="agrees_to_electronic_communication">
          I Agree to the terms and conditions in the &ldquo;Consent and Notice
          Regarding Electronic Communication&rdquo;.
        </label>

        <p>
          {`Type your name in the *Signature Block*. By typing in your name, you are signing this form electronically 
        and you agree your electronic signature is the legal equivalent of your manual signature on this form and 
        that you are authorizing the changes you have made on this form.`}
        </p>

        <div>
          <label htmlFor="electronic_signature">Signature</label>
          <Input
            id="electronic_signature"
            name="electronic_signature"
            required
          />
        </div>
        <div id="recaptcha-container">
          {typeof window !== "undefined" && (
            <Recaptcha
              elementId="recaptcha-container"
              render="explicit"
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
              verifyCallback={recaptchaVerifyCallback}
              onloadCallback={console.debug}
              expiredCallback={recaptchaExpiredCallback}
            />
          )}
        </div>
        <Input
          id="captcha_token"
          name="captcha_token"
          type="hidden"
          value={captchaToken}
          required
        />
        {isCaptchaVerified && (
          <p style={{ textAlign: "center" }}>
            <Button type="submit">Submit</Button>
          </p>
        )}
      </form>
    </Page>
  );
};
export default ChangeAddressForm;
